<div
  [ngClass]="{
  'custom-control custom-checkbox': default,
  'form-check': !default,
  'custom-control-inline': inline,
  'form-check-inline': inline && !default }">
  <input
    #input
    type="checkbox"
    class="custom-control-input"
    [ngClass]="{
      'filled-in': filledIn || rounded,
      'custom-control-input': default,
      'form-check-input': !default }"
    [id]="id"
    [checked]="checked"
    [disabled]="disabled"
    [required]="required"
    [indeterminate]="indeterminate"
    [attr.name]="name"
    [attr.value]="value"
    [tabIndex]="tabIndex"
    (blur)="onBlur()"
    (click)="onCheckboxClick($event)"
    (change)="onCheckboxChange($event)">
  <label
    [ngClass]="{
      'custom-control-label': default,
      'form-check-label': !default,
      'label-before': checkboxPosition === 'right',
      'checkbox-rounded': rounded,
      'disabled': disabled }"
    [attr.for]="id">
    <ng-content></ng-content>
  </label>
</div>
