<div
  tabindex="0"
  class="completer-row"
  [ngStyle]="{
    'height.px': _optionHeight ? _optionHeight : 'auto'
  }"
  mdbAutoCompleterOption
>
  <ng-content></ng-content>
</div>
