<!--Pagination -->
<nav>
  <ul
    class="pagination pagination-circle pg-blue d-flex flex-center"
    [ngClass]="{
      'justify-content-end': paginationAlign == 'end',
      'justify-content-start': paginationAlign == 'start'
    }"
  >
    <li *ngIf="!hideDescription">
      {{ firstItemIndex }} {{ dashKeyword }} {{ lastVisibleItemIndex }} {{ ofKeyword }}
      {{ allItemsLength }}
    </li>
    <!--Arrow left-->
    <li class="page-item" [ngClass]="{ disabled: checkIfPreviousShouldBeDisabled() }">
      <a class="page-link" mdbWavesEffect aria-label="Previous" (click)="firstPage()">
        <span aria-hidden="true">«</span>
      </a>
    </li>

    <li class="page-item" [ngClass]="{ disabled: checkIfPreviousShouldBeDisabled() }">
      <a class="page-link" mdbWavesEffect aria-label="Previous" (click)="previousPage()">
        <span aria-hidden="true">&#8249;</span>
      </a>
    </li>

    <li class="page-item" [ngClass]="{ disabled: checkIfNextShouldBeDisabled() }">
      <a class="page-link" mdbWavesEffect aria-label="Previous" (click)="nextPage()">
        <span aria-hidden="true">&#8250;</span>
      </a>
    </li>

    <!--Arrow right-->
    <li class="page-item" [ngClass]="{ disabled: checkIfNextShouldBeDisabled() }">
      <a class="page-link" mdbWavesEffect aria-label="Next" (click)="lastPage()">
        <span aria-hidden="true">»</span>
      </a>
    </li>
  </ul>
</nav>
<!--/Pagination -->
