<div #filter class="mdb-select-filter md-form px-2">
  <input
    #input
    [placeholder]="placeholder"
    [attr.autocomplete]="autocomplete"
    [attr.role]="'searchbox'"
    type="text"
    class="mdb-select-filter-input search form-control w-100 d-block"
  />
</div>
